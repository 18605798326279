<template>
  <div v-loading="meta.loading"
       element-loading-text="加载中,请稍后..."
       element-loading-background="rgba(0, 0, 0, 0.7)">
    <mt>
      <el-form :inline="true" align="left">
        <el-form-item label="查询条件">
          <el-date-picker v-model="params.sellDate" type="daterange" start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="params.customerId" filterable clearable remote placeholder="请输入客户姓名或电话"
                     :remote-method="_queryCustomers"
                     :loading="meta.loading">
            <el-option v-for="s in meta.customers" :value="s.id" :label="s.name+'('+s.phone+')'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.sellerId" filterable clearable remote placeholder="请输入销售姓名或电话"
                     :remote-method="_querySeller"
                     :loading="meta.loading">
            <el-option v-for="s in meta.users" :value="s.id" :label="s.name+'('+s.mobile+')'"></el-option>
          </el-select>
        </el-form-item>
        <br>
        <el-form-item label="查询已作废订单">
          <el-checkbox v-model="params.showInvalid" />
        </el-form-item>
        <el-form-item align="right">
          <el-button @click="onQuery">查询</el-button>
          <el-button @click="onExport">导出</el-button>
        </el-form-item>
      </el-form>
    </mt>
    <el-card style="margin: 30px" shadow="always">
      <div slot="header" class="clearfix">
        <span class="title">订单查询</span>
        <el-button class="title" style="margin-left: 20px" :disabled="!(multipleSelection.length)" @click="handleBatchInvalid">批量作废</el-button>
      </div>

      <el-table height="700px" :data="orders" stripe border id="rebateSetTable" @selection-change="handleSelectionChange" class="funBtn" tooltip-effect="light">
        <el-table-column type="selection" width="70" />
        <el-table-column label="NO." type="index" width="70"></el-table-column>
        <el-table-column label="下单日期" prop="createTime" min-width="220"></el-table-column>
        <el-table-column label="客户" prop="customerId" min-width="220">
          <template slot-scope="scope">
            {{ scope.row.customerName }}
            <template v-if="scope.row.customerPhone">({{ scope.row.customerPhone }})</template>
          </template>
        </el-table-column>
        <el-table-column label="销售员" prop="creatorId" min-width="190">
          <template slot-scope="scope">
            {{ scope.row.sellerName }}
            <template v-if="scope.row.sellerName">({{ scope.row.sellerPhone }})</template>
          </template>
        </el-table-column>
        <el-table-column label="订单金额" prop="money" width="120">
          <template slot-scope="scope">
            {{ scope.row.money | fen2yuan }}
          </template>
        </el-table-column>
        <el-table-column label="" prop="state">
          <template slot-scope="scope">
            <span v-if="scope.row.state==0" :style="{color:'red'}">待审核</span>
            <span v-if="scope.row.state==1">已审核</span>
            <span v-if="scope.row.state==2">已作废</span>
          </template>
        </el-table-column>
        <el-table-column label="商品" prop="goods" min-width="300px" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-for="s in scope.row.details"
                  style="margin-right: 40px">{{ s.goodsName || '' }}[{{ s.count }}]</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="340">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)">详情</el-button>
            <el-button v-if="(!isHr)&&(scope.row.state==0)" @click="edit(scope.row)">编辑</el-button>
            <el-button v-if="isAdmin&&(scope.row.state==0)" @click="review2(scope.row)">审核</el-button>
            <el-button v-if="isAdmin&&(scope.row.state==1)" @click="inValid(scope.row)">作废</el-button>
            <el-button v-if="isAdmin&&(scope.row.state==2)" @click="recover(scope.row)">恢复</el-button>
            <el-button v-if="scope.row.state==0" @click="orderDelete(scope.row)" type="danger">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination @size-change="onQuery"
                       @current-change="onQuery"
                       :current-page.sync="params.page"
                       :page-sizes="[50, 100]"
                       :page-size.sync="params.size"
                       layout="sizes, prev, pager, next,total"
                       :total="params.total">

        </el-pagination>
      </div>
    </el-card>
    <el-dialog :visible.sync="exportMeta.visible" width="500px" title="订单详情">
      <el-form size="mini">
        <el-form-item label="导出方式：">
          <div style="font-size: 14px">
            <div>
              <el-radio-group v-model="exportMeta.type" @change="handleChangeExportType">
                <el-radio :label="1">订单详情</el-radio>
                <el-radio :label="2">按客户汇总</el-radio>
              </el-radio-group>
            </div>
            <div style="padding-left: 120px">
              <div style="color: #999">按客户汇总查询,仅汇总导出<span style="color: red;">已审核</span>状态下单据的数据
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="   ">

        </el-form-item>
        <el-form-item label="导出范围：">
          <div>
            <div>
              <el-radio-group v-model="exportMeta.scope">
                <el-radio :label="1" :disabled="exportMeta.type==2">当前页</el-radio>
                <el-radio :label="2">全部数据</el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template slot="footer" class="dialog-footer">
        <el-button @click="exportMeta.visible = false">取 消</el-button>
        <el-button type="primary" @click="handleExportOk">确 定</el-button>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="reviewMeta.visible" width="800px" title="审核">
      <el-table :data="reviewMeta.row?.details" :max-height="500" border >
        <el-table-column label="货号" prop="itemNumber" min-width="120" width="220"/>
        <el-table-column label="商品名称" prop="goodsName" />
        <el-table-column label="数量" prop="count" width="100"/>
      </el-table>
      <template #footer>
        <div>
        <div style="color:orange;font-weight: bold">审核通过后，将不可恢复，是否继续?</div>
        <div style="margin-top: 20px">
          <el-button @click="handleReviewCancel">取 消</el-button>
          <el-button @click="handleReviewOk" type="primary">确 认</el-button></div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mt from "@/components/MainTop.vue"
import {lqDownload} from "assets/api/download";
import FileSaver from 'file-saver'
import XLSX from "xlsx";

export default {
  components: {mt},
  name: "List",
  data() {
    return {
      params: {
        nameOrPhone: "",
        sellerId: "",
        showInvalid:false,
        sellDate: [],
        page: 1,
        size: 50,
        total: 0
      },
      //已勾选表格列
      multipleSelection: [],
      client: "",
      orders: [],
      meta: {
        loading: false,
        users: [],
        customers: [],
      },
      exportMeta: {
        visible: false,
        type: 1,
        scope: 1
      },
      reviewMeta:{
        visible: false,
        row:{}
      }
    }
  },
  mounted() {
    localStorage.removeItem("cartGoods");
    this.onQuery()
  },
  computed:{
    isMultiSelection(){
      //return this.&refs.rebateSetTable.selection.length>0;
      return 'aaa'//||this.$refs.rebateSetTable.id
    }
  },
  methods: {
    detail(row) {
      this.$router.push({name: "OrderDetail", params: {orderId: row.id}})
    },
    onExport() {
      this.exportMeta.visible = true
    },
    handleExportOk() {
      let _this = this;
      this.exportMeta.visible = false
      this.meta.loading = true
      let param = {
        sellerId: this.params.sellerId,
        customerId: this.params.customerId,
        showInvalid: this.params.showInvalid,
        page: this.params.page,
        size: this.params.size || 50,
        exportType: this.exportMeta.type,
        exportScope: this.exportMeta.scope
      }
      if (this.params.sellDate && this.params.sellDate.length > 1) {
        param.startTimeStr = this.params.sellDate[0];
        param.endTimeStr = this.params.sellDate[1];
      }
      lqDownload("/api/order/export", param, '订单信息.xlsx').then(() => {
        _this.meta.loading = false
      })
    },
    handleChangeExportType(v) {
      if (v == 2) {
        this.exportMeta.scope = 2
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onQuery() {
      let _this = this;
      this.meta.loading = true
      let param = {
        sellerId: this.params.sellerId,
        customerId: this.params.customerId,
        showInvalid: this.params.showInvalid,
        invalid: this.params.invalid,
        page: this.params.page,
        size: this.params.size || 50
      }
      if (this.params.sellDate && this.params.sellDate.length > 1) {
        param.startTimeStr = this.params.sellDate[0];
        param.endTimeStr = this.params.sellDate[1];
      }
      this.$http.get("/api/order/find", {params: param})
          .then(rsp => {
            if (rsp.data.status == 200) {
              _this.orders = rsp.data.data || [];
              this.params.total = rsp.data.count;
              this.meta.loading = false
            }
          });
    },
    _querySeller(name) {
      this.$http.get("/api/seller/find", {params: {name: name, page: 0, size: 50, state: 1}})
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.meta.users = rsp.data.data || [];
            }
          }).catch(() => {
        this.meta.users = [];
      })
    },
    _queryCustomers(name) {
      this.meta.loading = true
      this.$http.get("/api/customer/find", {params: {name: name, size: 100, page: 1}})
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.meta.loading = false,
                  this.meta.customers = rsp.data.data || [];
            }
          }).catch(() => {
        this.meta.loading = false,
            this.meta.customers = [];
      })
    },
    orderDelete(row) {
      this.$confirm('删除后该订单不可恢复，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.$http.post("/api/order/delete/" + row.id)
            .then(rsp => {
              if (rsp.data.status == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.onQuery();
              }
            });

      });


    },
    edit(row) {
      localStorage.removeItem("cartGoods");
      this.$router.push({name: "OrderEdit", params: {orderId: row.id}})
    },
    review(row) {
      this.$confirm('审核通过后，将不可恢复，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/order/review/" + row.id)
            .then(rsp => {
              if (rsp.data.status == 200) {
                this.$message({
                  type: 'success',
                  message: '审核成功!'
                });
                this.onQuery();
              }
            })

      });
    },
    review2(row) {
      this.reviewMeta.row=row;
     this.reviewMeta.visible=true;
    },
    handleReviewCancel(){
      this.reviewMeta.row=undefined
      this.reviewMeta.visible=false
    },
    handleReviewOk(){
      let _this=this;
      this.$http.post("/api/order/review/" + _this.reviewMeta.row.id)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '审核成功!'
              });
              this.onQuery();
              this.handleReviewCancel()
            }
      })
    },
    //作废
    inValid(row){
      let _this=this;
      this.meta.loading = true
      this.$http.delete("/api/order/invalid/" + row.id)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.meta.loading = false
              this.onQuery();
            }
          })
    },
    handleBatchInvalid(){
      this.meta.loading = true
      let _ids=this.multipleSelection.map(item=>item.id).join(",");
      this.$http.delete("/api/order/invalid/" + _ids)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.meta.loading = false
              this.onQuery();
            }
          })
    },
    //恢复
    recover(row){
      let _this=this;
      this.meta.loading = true
      this.$http.get("/api/order/recover/" + row.id)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.meta.loading = false
              this.onQuery();
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
